import { useEffect, useState } from 'react';
import { Button } from './ui/button';
import { useToast } from './ui/use-toast';
import { supabase } from '@/integrations/supabase/client';

export const Ad = () => {
  const [hasPaid, setHasPaid] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const checkPaymentStatus = async () => {
    try {
      console.log('Checking payment status...');
      const { data: { hasPaid }, error } = await supabase.functions.invoke('check-payment-status');
      
      if (error) {
        console.error('Error from check-payment-status:', error);
        throw error;
      }
      
      console.log('Payment status response:', { hasPaid });
      setHasPaid(hasPaid);
    } catch (error) {
      console.error('Error checking payment status:', error);
      setHasPaid(false);
    }
  };

  useEffect(() => {
    checkPaymentStatus();
  }, []);

  const handlePurchase = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase.functions.invoke('create-checkout');
      
      if (error) throw error;
      
      if (data?.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to start checkout process",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Add loading state
  if (hasPaid === null) {
    return null;
  }

  // If user has paid, don't show the ad
  if (hasPaid === true) {
    console.log('User has paid, hiding ad');
    return null;
  }

  return (
    <div className="w-full bg-gray-800/30 backdrop-blur-sm rounded-xl p-4 mb-4">
      <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="flex-1 text-center sm:text-left">
          <p className="text-gray-400">Advertisement Space</p>
          <p className="text-sm text-gray-500">Remove ads forever with a one-time purchase!</p>
        </div>
        <Button
          onClick={handlePurchase}
          disabled={isLoading}
          className="w-full sm:w-auto bg-primary hover:bg-primary/90"
        >
          {isLoading ? "Loading..." : "Remove Ads"}
        </Button>
      </div>
    </div>
  );
};