import { Activities } from '@/types/activities';
import { ViewType } from '@/types/view';
import ActivityGrid from './ActivityGrid';
import WeekView from './WeekView';
import YearOverview from './YearOverview';
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { useIsMobile } from '@/hooks/use-mobile';

interface MainContentProps {
  currentView: ViewType;
  currentDate: Date;
  activities: Activities;
  selectedColor: string;
  onToggleActivity: (date: string) => void;
  onCarouselDrag: (event: any) => void;
  isLocked: boolean;
}

export const MainContent = ({
  currentView,
  currentDate,
  activities,
  selectedColor,
  onToggleActivity,
  onCarouselDrag,
  isLocked
}: MainContentProps) => {
  const isMobile = useIsMobile();

  const renderContent = () => {
    if (currentView === 'year') {
      return (
        <YearOverview
          year={currentDate.getFullYear()}
          activities={activities}
        />
      );
    }

    if (isMobile) {
      return (
        <Carousel
          opts={{
            align: 'start',
            loop: true,
          }}
          onDragEnd={onCarouselDrag}
          className="w-full"
        >
          <CarouselContent>
            <CarouselItem className="basis-full">
              {currentView === 'month' ? (
                <ActivityGrid
                  activities={activities}
                  onToggleActivity={onToggleActivity}
                  selectedColor={selectedColor}
                  currentMonth={currentDate}
                  isLocked={isLocked}
                />
              ) : (
                <WeekView
                  currentDate={currentDate}
                  activities={activities}
                  onToggleActivity={onToggleActivity}
                  selectedColor={selectedColor}
                  isLocked={isLocked}
                />
              )}
            </CarouselItem>
          </CarouselContent>
        </Carousel>
      );
    }

    return currentView === 'month' ? (
      <ActivityGrid
        activities={activities}
        onToggleActivity={onToggleActivity}
        selectedColor={selectedColor}
        currentMonth={currentDate}
        isLocked={isLocked}
      />
    ) : (
      <WeekView
        currentDate={currentDate}
        activities={activities}
        onToggleActivity={onToggleActivity}
        selectedColor={selectedColor}
        isLocked={isLocked}
      />
    );
  };

  return (
    <div className="bg-gray-800/30 rounded-xl backdrop-blur-sm p-2 sm:p-4 mb-6 flex-grow">
      <div className="h-full min-h-[400px]">
        {renderContent()}
      </div>
    </div>
  );
};