import { supabase } from "@/integrations/supabase/client";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Activities } from "@/types/activities";
import { useToast } from "@/components/ui/use-toast";

interface Profile {
  activities: Activities;
  is_locked: boolean;
}

export const useProfile = (userId: string) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data: profile, isLoading } = useQuery({
    queryKey: ['profile', userId],
    queryFn: async () => {
      // Don't fetch if there's no userId
      if (!userId) {
        return { activities: {}, is_locked: false } as Profile;
      }

      const { data, error } = await supabase
        .from('profiles')
        .select('activities, is_locked')
        .eq('id', userId)
        .single();

      if (error) throw error;
      return data as Profile;
    },
    // Only enable the query if we have a userId
    enabled: Boolean(userId),
  });

  const updateProfile = useMutation({
    mutationFn: async (updates: Partial<Profile>) => {
      if (!userId) {
        throw new Error('No user ID available');
      }

      const { error } = await supabase
        .from('profiles')
        .update(updates)
        .eq('id', userId);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile', userId] });
    },
    onError: (error) => {
      console.error('Error updating profile:', error);
      toast({
        title: "Failed to update profile",
        variant: "destructive",
        duration: 2000,
      });
    },
  });

  return {
    profile,
    isLoading,
    updateProfile,
  };
};