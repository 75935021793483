import { Header } from '@/components/Header';
import { Ad } from '@/components/Ad';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="min-h-screen bg-background p-2 sm:p-4 md:p-8">
      <div className="max-w-2xl mx-auto flex flex-col min-h-[600px]">
        <Header />
        <Ad />
        {children}
      </div>
    </div>
  );
};