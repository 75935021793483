import React from 'react';
import { format, startOfWeek, addDays, isSameDay } from 'date-fns';
import { Moon, Sun, Clock } from 'lucide-react';

interface WeekViewProps {
  currentDate: Date;
  activities: Record<string, { 
    color: string; 
    isNightShift: boolean;
    hours?: string;
  }>;
  onToggleActivity: (date: string) => void;
  selectedColor: string;
  isLocked: boolean;
}

const WeekView: React.FC<WeekViewProps> = React.memo(({
  currentDate,
  activities,
  onToggleActivity,
  selectedColor,
  isLocked,
}) => {
  const weekStart = startOfWeek(currentDate);
  const weekDays = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

  // Format hours to a shorter display format
  const formatHours = (hours?: string) => {
    if (!hours) return null;
    const [start, end] = hours.split('-');
    if (!start || !end) return null;
    
    // Extract just the hours without minutes
    const startHour = start.split(':')[0];
    const endHour = end.split(':')[0];
    
    return `${startHour}-${endHour}`;
  };

  return (
    <div className="p-2">
      <div className="grid grid-cols-7 gap-2">
        {weekDays.map((day) => {
          const dateStr = format(day, 'yyyy-MM-dd');
          const activity = activities[dateStr];
          const isToday = isSameDay(day, new Date());
          const formattedHours = activity?.hours ? formatHours(activity.hours) : null;

          return (
            <div key={dateStr} className="flex flex-col items-center">
              <div className="text-sm text-gray-400 mb-1">
                {format(day, 'EEE')}
              </div>
              <button
                onClick={() => onToggleActivity(dateStr)}
                disabled={isLocked}
                className={`
                  group
                  w-full aspect-square rounded-lg
                  ${activity ? `${activity.color} shadow-lg` : 'bg-gray-800'}
                  ${isToday ? 'ring-2 ring-white ring-offset-2 ring-offset-background' : ''}
                  ${isLocked ? 'cursor-not-allowed' : 'hover:scale-105 hover:shadow-xl active:scale-95'}
                  flex flex-col items-center justify-center relative
                  transform transition-transform duration-100
                `}
              >
                <span className="text-lg font-bold text-white">
                  {format(day, 'd')}
                </span>
                {activity && (
                  <>
                    <div className="absolute bottom-1 right-1">
                      {activity.isNightShift ? (
                        <Moon className="w-3 h-3 text-white opacity-80" />
                      ) : (
                        <Sun className="w-3 h-3 text-white opacity-80" />
                      )}
                    </div>
                    {activity.hours && (
                      <>
                        <div className="absolute top-1 left-1 flex items-center gap-0.5">
                          <Clock className="w-3 h-3 text-white opacity-80" />
                          <span className="text-[9px] text-white opacity-80">{formattedHours}</span>
                        </div>
                        <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity bg-black/80 text-white text-xs px-2 py-1 rounded -top-10 whitespace-nowrap z-10">
                          {activity.hours}
                        </div>
                      </>
                    )}
                  </>
                )}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
});

WeekView.displayName = 'WeekView';

export default WeekView;