import React from 'react';
import { format, eachDayOfInterval, startOfMonth, endOfMonth, startOfWeek, endOfWeek, isSameDay } from 'date-fns';
import { Moon, Sun, Clock } from 'lucide-react';

interface ActivityGridProps {
  activities: Record<string, { 
    color: string; 
    isNightShift: boolean;
    hours?: string;
  }>;
  onToggleActivity: (date: string) => void;
  selectedColor: string;
  currentMonth: Date;
  isLocked: boolean;
}

const ActivityGrid: React.FC<ActivityGridProps> = React.memo(({ 
  activities, 
  onToggleActivity, 
  selectedColor, 
  currentMonth,
  isLocked 
}) => {
  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const calendarStart = startOfWeek(monthStart);
  const calendarEnd = endOfWeek(monthEnd);
  
  const days = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

  // Format hours to a shorter display format
  const formatHours = (hours?: string) => {
    if (!hours) return null;
    const [start, end] = hours.split('-');
    if (!start || !end) return null;
    
    // Extract just the hours without minutes
    const startHour = start.split(':')[0];
    const endHour = end.split(':')[0];
    
    return `${startHour}-${endHour}`;
  };

  return (
    <div className="grid grid-cols-7 gap-1 sm:gap-2 p-2 sm:p-4">
      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
        <div key={day} className="text-center text-xs sm:text-sm text-gray-400 mb-1 sm:mb-2">
          {day}
        </div>
      ))}
      {days.map((date) => {
        const dateStr = format(date, 'yyyy-MM-dd');
        const activity = activities[dateStr];
        const isCurrentMonth = format(date, 'M') === format(currentMonth, 'M');
        const isToday = isSameDay(date, new Date());
        const formattedHours = activity?.hours ? formatHours(activity.hours) : null;
        
        return (
          <button
            key={dateStr}
            onClick={() => onToggleActivity(dateStr)}
            className={`
              group
              aspect-square rounded-lg min-h-[40px] sm:min-h-[50px]
              ${activity ? `${activity.color} shadow-lg` : 'bg-gray-800'}
              ${!isCurrentMonth ? 'opacity-30' : ''}
              ${isToday ? 'ring-2 ring-white' : ''}
              ${isLocked ? 'cursor-not-allowed' : 'hover:scale-105 hover:shadow-xl active:scale-95'}
              flex items-center justify-center relative
              transform transition-transform duration-100
            `}
            disabled={isLocked}
            aria-label={`Toggle activity for ${format(date, 'MMMM d, yyyy')}`}
          >
            <span className="text-xs sm:text-sm text-white opacity-80 font-bold">
              {format(date, 'd')}
            </span>
            {activity && (
              <>
                <div className="absolute bottom-1 right-1">
                  {activity.isNightShift ? (
                    <Moon className="w-3 h-3 text-white opacity-80" />
                  ) : (
                    <Sun className="w-3 h-3 text-white opacity-80" />
                  )}
                </div>
                {activity.hours && (
                  <>
                    <div className="absolute top-1 left-1 flex items-center gap-0.5">
                      <Clock className="w-2 h-2 text-white opacity-80" />
                      <span className="text-[8px] text-white opacity-80">{formattedHours}</span>
                    </div>
                    <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity bg-black/80 text-white text-[10px] px-2 py-1 rounded -top-8 whitespace-nowrap z-10">
                      {activity.hours}
                    </div>
                  </>
                )}
              </>
            )}
          </button>
        );
      })}
    </div>
  );
});

ActivityGrid.displayName = 'ActivityGrid';

export default ActivityGrid;