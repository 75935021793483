import { Button } from "@/components/ui/button";
import { LogOut } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";

export const Header = () => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleLogout = async () => {
    try {
      // First clear any local storage or state
      localStorage.clear();
      
      // Attempt to sign out from Supabase
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error("Logout error:", error);
        // If it's a session not found error, we can still proceed with the logout
        if (error.message.includes("session_not_found")) {
          navigate('/login');
          toast({
            title: "Logged out successfully",
            duration: 2000,
          });
          return;
        }
        
        // For other errors, show an error message
        toast({
          title: "Error logging out",
          description: "Please try again",
          variant: "destructive",
          duration: 3000,
        });
        return;
      }
      
      // Navigate to login page
      navigate('/login');
      
      toast({
        title: "Logged out successfully",
        duration: 2000,
      });
    } catch (error) {
      console.error("Unexpected error during logout:", error);
      toast({
        title: "Error logging out",
        description: "Please try again",
        variant: "destructive",
        duration: 3000,
      });
    }
  };

  return (
    <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-0 mb-4 p-4">
      <div className="flex items-center gap-1.5 order-1 sm:order-2 sm:flex-1 sm:justify-center">
        <img 
          src="/lovable-uploads/55a12f54-09c9-47b2-b297-e587ce44f4ae.png" 
          alt="ShiftBear Logo" 
          className="w-16 h-16"
        />
        <h1 className="text-xl font-bold text-white font-rounded">ShiftBear</h1>
      </div>
      <div className="w-full sm:w-auto order-2 sm:order-1 sm:flex-1">
        <Button
          variant="ghost"
          size="sm"
          onClick={handleLogout}
          className="text-gray-400 hover:text-white w-full sm:w-auto"
        >
          <LogOut className="w-4 h-4 mr-2" />
          Logout
        </Button>
      </div>
      <div className="hidden sm:block sm:flex-1 order-3"></div>
    </div>
  );
};