import React from 'react';

interface ColorButtonProps {
  name: string;
  value: string;
  isSelected: boolean;
  onClick: () => void;
}

export const ColorButton: React.FC<ColorButtonProps> = ({ 
  name, 
  value, 
  isSelected, 
  onClick 
}) => {
  return (
    <button
      className={`w-8 h-8 rounded-full ${value} transition-transform hover:scale-110 
        ${isSelected ? 'ring-2 ring-white' : ''}`}
      onClick={onClick}
      aria-label={`Select ${name}`}
    />
  );
};