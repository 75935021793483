import { Button } from "@/components/ui/button";
import { format } from "date-fns";
import { ViewType } from "@/types/view";

interface DateNavigationProps {
  currentDate: Date;
  currentView: ViewType;
  onPrevious: () => void;
  onNext: () => void;
}

export const DateNavigation = ({ 
  currentDate, 
  currentView, 
  onPrevious, 
  onNext 
}: DateNavigationProps) => {
  const getHeaderText = () => {
    if (currentView === 'month') return format(currentDate, 'MMMM yyyy');
    if (currentView === 'week') return `Week of ${format(currentDate, 'MMM d, yyyy')}`;
    return format(currentDate, 'yyyy');
  };

  return (
    <div className="flex items-center justify-center gap-12 mb-6 sm:mb-10 py-4">
      <Button 
        variant="ghost" 
        onClick={onPrevious}
        className="text-white hover:bg-transparent"
      >
        <span className="sr-only">Previous</span>
        ←
      </Button>
      <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-white text-center min-w-[200px]">
        {getHeaderText()}
      </h1>
      <Button 
        variant="ghost" 
        onClick={onNext}
        className="text-white hover:bg-transparent"
      >
        <span className="sr-only">Next</span>
        →
      </Button>
    </div>
  );
};