import React from 'react';
import { Switch } from "@/components/ui/switch";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

interface ControlPanelProps {
  isLocked: boolean;
  onToggleLock: () => void;
  onReset: () => void;
}

export const ControlPanel: React.FC<ControlPanelProps> = ({
  isLocked,
  onToggleLock,
  onReset
}) => {
  return (
    <div className="flex flex-col items-center gap-6">
      <div className="flex items-center gap-2 bg-gray-800/30 px-4 py-2 rounded-lg">
        <span className="text-sm text-gray-400">Lock Calendar</span>
        <Switch
          checked={isLocked}
          onCheckedChange={onToggleLock}
        />
      </div>

      <div className="border-t border-gray-700 w-full my-2" />

      <AlertDialog>
        <AlertDialogTrigger className="text-sm text-red-500 hover:text-red-400 bg-gray-800/30 px-4 py-2 rounded-lg transition-colors">
          Reset All
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action will remove all your activities. This cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={onReset}
              className="bg-red-500 hover:bg-red-600"
            >
              Reset
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};