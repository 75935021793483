import React, { useState } from 'react';
import { ChevronUp, ChevronDown, Sun, Moon, Clock } from 'lucide-react';
import { ColorGrid } from './color-picker/ColorGrid';
import { ControlPanel } from './color-picker/ControlPanel';

interface ShiftPickerProps {
  selectedColor: string;
  selectedShiftType: 'day' | 'night';
  selectedHours?: string;
  onColorSelect: (color: string) => void;
  onShiftTypeSelect: (type: 'day' | 'night') => void;
  onHoursSelect: (hours: string | undefined) => void;
  isLocked: boolean;
  onToggleLock: () => void;
  onReset: () => void;
}

const ShiftPicker: React.FC<ShiftPickerProps> = ({ 
  selectedColor, 
  selectedShiftType,
  selectedHours,
  onColorSelect,
  onShiftTypeSelect,
  onHoursSelect,
  isLocked,
  onToggleLock,
  onReset
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [showHoursPicker, setShowHoursPicker] = useState(false);
  
  const commonHours = [
    { label: 'Day (9-5)', value: '09:00-17:00' },
    { label: 'Early (7-3)', value: '07:00-15:00' },
    { label: 'Late (13-21)', value: '13:00-21:00' },
    { label: 'Night (21-7)', value: '21:00-07:00' },
  ];
  
  return (
    <div className="flex flex-col items-center gap-4">
      <div className="flex flex-col items-center gap-2">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center gap-2 px-4 py-2 text-sm text-white bg-gray-800/30 rounded-lg hover:bg-gray-700/30 transition-colors"
          aria-expanded={isExpanded}
        >
          <span>Select Shift</span>
          {isExpanded ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
        </button>
        
        {isExpanded && (
          <div className="flex flex-col gap-4 p-4 bg-gray-800/30 rounded-xl backdrop-blur-sm w-[280px] mx-auto animate-slide-down">
            <div className="flex justify-center gap-2 p-2">
              <button
                onClick={() => onShiftTypeSelect('day')}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all ${
                  selectedShiftType === 'day' 
                    ? 'bg-white text-gray-900 shadow-lg scale-105' 
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                }`}
              >
                <Sun className="w-4 h-4" />
                <span>Day Shift</span>
              </button>
              <button
                onClick={() => onShiftTypeSelect('night')}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all ${
                  selectedShiftType === 'night' 
                    ? 'bg-white text-gray-900 shadow-lg scale-105' 
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                }`}
              >
                <Moon className="w-4 h-4" />
                <span>Night Shift</span>
              </button>
            </div>

            <div className="border-t border-gray-700 w-full my-2" />

            <div className="flex flex-col gap-2">
              <button
                onClick={() => setShowHoursPicker(!showHoursPicker)}
                className="flex items-center justify-between w-full px-4 py-2 text-sm text-white bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
              >
                <div className="flex items-center gap-2">
                  <Clock className="w-4 h-4" />
                  <span>{selectedHours || 'Add Hours'}</span>
                </div>
                {showHoursPicker ? (
                  <ChevronUp className="w-4 h-4" />
                ) : (
                  <ChevronDown className="w-4 h-4" />
                )}
              </button>

              {showHoursPicker && (
                <div className="flex flex-col gap-2 p-2 bg-gray-700 rounded-lg animate-slide-down">
                  {commonHours.map((hours) => (
                    <button
                      key={hours.value}
                      onClick={() => {
                        onHoursSelect(selectedHours === hours.value ? undefined : hours.value);
                        setShowHoursPicker(false);
                      }}
                      className={`flex items-center justify-between px-3 py-2 rounded-lg transition-all ${
                        selectedHours === hours.value
                          ? 'bg-white text-gray-900'
                          : 'text-white hover:bg-gray-600'
                      }`}
                    >
                      {hours.label}
                    </button>
                  ))}
                  <button
                    onClick={() => {
                      onHoursSelect(undefined);
                      setShowHoursPicker(false);
                    }}
                    className="text-red-400 hover:text-red-300 text-sm px-3 py-2 rounded-lg hover:bg-gray-600 transition-colors text-left"
                  >
                    Clear Hours
                  </button>
                </div>
              )}
            </div>

            <div className="border-t border-gray-700 w-full my-2" />
            
            <ColorGrid
              selectedColor={selectedColor}
              onColorSelect={onColorSelect}
            />
          </div>
        )}
      </div>

      <ControlPanel
        isLocked={isLocked}
        onToggleLock={onToggleLock}
        onReset={onReset}
      />
    </div>
  );
};

export default ShiftPicker;