import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          // Store session in localStorage
          localStorage.setItem('sb-session', JSON.stringify(session));
          navigate('/');
        }
      } catch (error) {
        console.error('Session check error:', error);
      }
    };
    
    checkUser();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_IN' && session) {
        // Store session in localStorage
        localStorage.setItem('sb-session', JSON.stringify(session));
        navigate('/');
      } else if (event === 'SIGNED_OUT') {
        localStorage.removeItem('sb-session');
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <div className="w-full max-w-md space-y-4">
        <div className="text-center mb-8">
          <img 
            src="/lovable-uploads/55a12f54-09c9-47b2-b297-e587ce44f4ae.png" 
            alt="ShiftBear Logo" 
            className="w-32 h-32 mx-auto mb-4"
          />
          <h1 className="text-3xl font-bold text-white mb-2 font-rounded">ShiftBear</h1>
          <p className="text-white/80 font-rounded">Sign in to manage your shifts</p>
        </div>
        <div className="bg-card/30 p-6 rounded-lg backdrop-blur-sm shadow-xl">
          <Auth
            supabaseClient={supabase}
            appearance={{
              theme: ThemeSupa,
              variables: {
                default: {
                  colors: {
                    brand: '#9F7AEA',
                    brandAccent: '#805AD5',
                    inputBackground: 'rgba(0,0,0,0.2)',
                    inputText: 'white',
                    inputPlaceholder: 'rgba(255,255,255,0.5)',
                  },
                },
              },
              className: {
                container: 'font-rounded',
                button: 'font-rounded shadow-lg !bg-primary hover:!bg-primary/90 transition-colors',
                input: 'font-rounded !border-0 !shadow-lg !shadow-black/20',
                label: '!text-white/90',
                anchor: '!text-white/80 hover:!text-white transition-colors',
              },
            }}
            theme="dark"
            providers={[]}
            localization={{
              variables: {
                sign_in: {
                  email_label: 'Email',
                  password_label: 'Password',
                }
              }
            }}
            magicLink={false}
            redirectTo={window.location.origin}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;