// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://fvxjpflxsffcekxutske.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ2eGpwZmx4c2ZmY2VreHV0c2tlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQwOTE4NjgsImV4cCI6MjA0OTY2Nzg2OH0.LEjUUIqLjoW7uHATDtHDU7QJQaymN3CDrLfUSKXZJ1U";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(
  SUPABASE_URL,
  SUPABASE_PUBLISHABLE_KEY,
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
      storage: window.localStorage
    }
  }
);