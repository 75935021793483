import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { Switch } from "@/components/ui/switch";
import { ViewType } from "@/types/view";
import { ViewSelector } from "./ViewSelector";

interface HeaderControlsProps {
  currentView: ViewType;
  onViewChange: (view: ViewType) => void;
  isLocked: boolean;
  onToggleLock: () => void;
  onReset: () => void;
}

export const HeaderControls = ({
  currentView,
  onViewChange,
}: HeaderControlsProps) => {
  return (
    <div className="mb-6">
      <ViewSelector 
        currentView={currentView} 
        onViewChange={onViewChange}
      />
    </div>
  );
};