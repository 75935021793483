import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ViewType } from '@/types/view';

interface ViewSelectorProps {
  currentView: ViewType;
  onViewChange: (value: ViewType) => void;
}

export const ViewSelector = ({ currentView, onViewChange }: ViewSelectorProps) => {
  return (
    <Tabs 
      value={currentView} 
      onValueChange={(value) => onViewChange(value as ViewType)}
      className="mb-6"
    >
      <TabsList className="grid w-full grid-cols-3 bg-gray-800/30">
        <TabsTrigger 
          value="month"
          className="text-white data-[state=active]:bg-purple-400"
        >
          Month
        </TabsTrigger>
        <TabsTrigger 
          value="week"
          className="text-white data-[state=active]:bg-purple-400"
        >
          Week
        </TabsTrigger>
        <TabsTrigger 
          value="year"
          className="text-white data-[state=active]:bg-purple-400"
        >
          Year
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
};