import React, { useMemo } from 'react';
import { format, getDaysInMonth, startOfMonth, getDay, isSameDay } from 'date-fns';
import { Moon, Sun, Calendar, Clock } from 'lucide-react';

interface YearOverviewProps {
  year: number;
  activities: Record<string, { 
    color: string; 
    isNightShift: boolean;
    hours?: string;
  }>;
}

const YearOverview: React.FC<YearOverviewProps> = ({ year, activities }) => {
  const months = Array.from({ length: 12 }, (_, i) => i);
  const today = new Date();

  // Calculate statistics
  const stats = useMemo(() => {
    const totalShifts = Object.keys(activities).length;
    const nightShifts = Object.values(activities).filter(a => a.isNightShift).length;
    const dayShifts = totalShifts - nightShifts;
    
    // Calculate total hours if available
    const totalHours = Object.values(activities)
      .filter(a => a.hours)
      .reduce((acc, curr) => {
        const [start, end] = curr.hours?.split('-') || [];
        if (start && end) {
          const [startHour] = start.split(':').map(Number);
          const [endHour] = end.split(':').map(Number);
          const hours = endHour > startHour ? endHour - startHour : (24 - startHour) + endHour;
          return acc + hours;
        }
        return acc;
      }, 0);
    
    return {
      total: totalShifts,
      night: nightShifts,
      day: dayShifts,
      totalHours
    };
  }, [activities]);
  
  // Format hours to a shorter display format
  const formatHours = (hours?: string) => {
    if (!hours) return null;
    const [start, end] = hours.split('-');
    if (!start || !end) return null;
    
    // Extract just the hours without minutes
    const startHour = start.split(':')[0];
    const endHour = end.split(':')[0];
    
    return `${startHour}-${endHour}`;
  };
  
  const getMonthDays = (month: number) => {
    const firstDay = startOfMonth(new Date(year, month));
    const daysInMonth = getDaysInMonth(new Date(year, month));
    const startingDayIndex = getDay(firstDay);
    
    const days = Array(42).fill(null);
    
    for (let i = 0; i < daysInMonth; i++) {
      const currentDate = new Date(year, month, i + 1);
      const date = format(currentDate, 'yyyy-MM-dd');
      const activity = activities[date];
      days[startingDayIndex + i] = {
        day: i + 1,
        hasActivity: !!activity,
        activityColor: activity?.color || '',
        isNightShift: activity?.isNightShift || false,
        hours: activity?.hours,
        formattedHours: activity?.hours ? formatHours(activity.hours) : null,
        isToday: isSameDay(currentDate, today)
      };
    }
    
    return days;
  };

  return (
    <div className="space-y-8">
      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 px-4">
        <div className="bg-gray-800/50 rounded-xl p-4 flex items-center gap-3">
          <Calendar className="w-5 h-5 text-white opacity-80" />
          <div>
            <div className="text-sm text-gray-400">Total Shifts</div>
            <div className="text-xl font-bold text-white">{stats.total}</div>
          </div>
        </div>
        <div className="bg-gray-800/50 rounded-xl p-4 flex items-center gap-3">
          <Sun className="w-5 h-5 text-white opacity-80" />
          <div>
            <div className="text-sm text-gray-400">Day Shifts</div>
            <div className="text-xl font-bold text-white">{stats.day}</div>
          </div>
        </div>
        <div className="bg-gray-800/50 rounded-xl p-4 flex items-center gap-3">
          <Moon className="w-5 h-5 text-white opacity-80" />
          <div>
            <div className="text-sm text-gray-400">Night Shifts</div>
            <div className="text-xl font-bold text-white">{stats.night}</div>
          </div>
        </div>
        <div className="bg-gray-800/50 rounded-xl p-4 flex items-center gap-3">
          <Clock className="w-5 h-5 text-white opacity-80" />
          <div>
            <div className="text-sm text-gray-400">Total Hours</div>
            <div className="text-xl font-bold text-white">{stats.totalHours || 0}</div>
          </div>
        </div>
      </div>

      {/* Calendar Grid */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 p-4">
        {months.map(month => {
          const days = getMonthDays(month);
          
          return (
            <div
              key={month}
              className="bg-gray-800/50 rounded-xl p-4 backdrop-blur-sm transition-colors hover:bg-gray-800/60"
            >
              <h3 className="text-lg font-semibold text-white mb-4">
                {format(new Date(year, month), 'MMMM')}
              </h3>
              <div className="grid grid-cols-7 gap-1">
                {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, i) => (
                  <div key={i} className="text-gray-400 text-center text-[10px] mb-1">
                    {day}
                  </div>
                ))}
                {days.map((day, i) => (
                  <div
                    key={i}
                    className={`
                      group relative aspect-square flex items-center justify-center text-[10px] rounded-sm
                      ${day === null 
                        ? 'text-transparent' 
                        : day.hasActivity
                        ? day.activityColor
                        : 'text-gray-400'
                      }
                      ${day?.isToday ? 'ring-2 ring-white ring-offset-1 ring-offset-gray-800' : ''}
                      ${day?.hasActivity ? 'hover:opacity-80 transition-opacity' : ''}
                    `}
                  >
                    <span className={`${day?.hasActivity ? 'text-white' : 'text-gray-400'}`}>
                      {day?.day || '.'}
                    </span>
                    {day?.hasActivity && (
                      <>
                        <div className="absolute bottom-0 right-0">
                          {day.isNightShift ? (
                            <Moon className="w-2 h-2 text-white opacity-80" />
                          ) : (
                            <Sun className="w-2 h-2 text-white opacity-80" />
                          )}
                        </div>
                        {day.hours && (
                          <>
                            <div className="absolute top-0 left-0 flex items-center">
                              <span className="text-[6px] text-white opacity-80">{day.formattedHours}</span>
                            </div>
                            <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity bg-black/80 text-white text-[8px] px-1 py-0.5 rounded -top-5 whitespace-nowrap">
                              {day.hours}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default YearOverview;