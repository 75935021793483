import React from 'react';
import { ColorButton } from './ColorButton';

interface ColorGridProps {
  selectedColor: string;
  onColorSelect: (color: string) => void;
}

export const ColorGrid: React.FC<ColorGridProps> = ({ 
  selectedColor, 
  onColorSelect 
}) => {
  const colors = [
    { name: 'Purple', value: 'bg-purple' },
    { name: 'Green', value: 'bg-green' },
    { name: 'Yellow', value: 'bg-yellow' },
    { name: 'Pink', value: 'bg-pink' },
    { name: 'Magenta', value: 'bg-magenta' },
    { name: 'Sky', value: 'bg-sky' },
    { name: 'Red', value: 'bg-red' },
    { name: 'Ocean', value: 'bg-ocean' },
    { name: 'Primary', value: 'bg-primary' },
    { name: 'Secondary', value: 'bg-secondary' },
    { name: 'Accent', value: 'bg-accent' },
    { name: 'Highlight', value: 'bg-highlight' },
  ];

  return (
    <div className="grid grid-cols-4 gap-3 p-4 bg-gray-800/30 rounded-xl backdrop-blur-sm w-[280px] mx-auto animate-slide-down">
      {colors.map((color) => (
        <ColorButton
          key={color.value}
          name={color.name}
          value={color.value}
          isSelected={selectedColor === color.value}
          onClick={() => onColorSelect(color.value)}
        />
      ))}
    </div>
  );
};