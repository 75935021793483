import React, { useState, useEffect } from 'react';
import { addMonths, subMonths, addWeeks, subWeeks } from 'date-fns';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/components/ui/use-toast';
import ColorPicker from '../components/ColorPicker';
import { Layout } from '@/components/Layout';
import { MainContent } from '@/components/MainContent';
import { DateNavigation } from '@/components/DateNavigation';
import { ViewType } from '@/types/view';
import { HeaderControls } from '@/components/HeaderControls';
import { useProfile } from '@/hooks/useProfile';
import ShiftPicker from '../components/ColorPicker';

// Helper function to check if activities need migration
const needsMigration = (activities: any): boolean => {
  if (!activities || Object.keys(activities).length === 0) return false;
  const firstActivity = Object.values(activities)[0];
  return typeof firstActivity === 'string';
};

// Helper function to migrate old activities to new format
const migrateActivities = (oldActivities: Record<string, string>): Record<string, { color: string; isNightShift: boolean }> => {
  const newActivities: Record<string, { color: string; isNightShift: boolean }> = {};
  
  Object.entries(oldActivities).forEach(([date, color]) => {
    if (typeof color === 'string') {
      newActivities[date] = {
        color,
        isNightShift: false // Default all existing activities to day shift
      };
    }
  });
  
  return newActivities;
};

const Index = () => {
  const [selectedColor, setSelectedColor] = useState('bg-primary');
  const [selectedShiftType, setSelectedShiftType] = useState<'day' | 'night'>('day');
  const [selectedHours, setSelectedHours] = useState<string>();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState<ViewType>('month');
  const [userId, setUserId] = useState<string>();
  const { toast } = useToast();

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUserId(session?.user?.id);
    };
    getSession();
  }, []);

  const { profile, isLoading, updateProfile } = useProfile(userId || '');
  let activities = profile?.activities || {};

  // Check and migrate activities if needed
  useEffect(() => {
    if (profile?.activities && needsMigration(profile.activities)) {
      const migratedActivities = migrateActivities(profile.activities as Record<string, string>);
      
      updateProfile.mutate(
        { activities: migratedActivities },
        {
          onSuccess: () => {
            toast({
              title: "Calendar data updated successfully",
              description: "Your schedule has been updated to support day and night shifts.",
              duration: 4000,
            });
          }
        }
      );
    }
  }, [profile?.activities]);

  const isLocked = profile?.is_locked || false;

  const handleToggleActivity = async (date: string) => {
    if (isLocked) return;

    const newActivities = { ...activities };
    if (
      newActivities[date]?.color === selectedColor && 
      newActivities[date]?.isNightShift === (selectedShiftType === 'night') &&
      newActivities[date]?.hours === selectedHours
    ) {
      delete newActivities[date];
    } else {
      newActivities[date] = {
        color: selectedColor,
        isNightShift: selectedShiftType === 'night',
        hours: selectedHours
      };
    }

    updateProfile.mutate(
      { activities: newActivities },
      {
        optimisticData: (old) => ({
          ...old,
          activities: newActivities
        }),
        rollbackOnError: true
      }
    );
  };

  const handleToggleLock = async () => {
    const newLockState = !isLocked;
    updateProfile.mutate(
      { is_locked: newLockState },
      {
        onSuccess: () => {
          toast({
            title: newLockState ? "Calendar locked" : "Calendar unlocked",
            duration: 2000,
          });
        }
      }
    );
  };

  const handleResetActivities = async () => {
    updateProfile.mutate(
      { activities: {} },
      {
        onSuccess: () => {
          toast({
            title: "Activities reset successfully",
            duration: 2000,
          });
        }
      }
    );
  };

  const handleNext = () => {
    if (currentView === 'month') {
      setCurrentDate(current => addMonths(current, 1));
    } else if (currentView === 'week') {
      setCurrentDate(current => addWeeks(current, 1));
    } else {
      setCurrentDate(current => new Date(current.getFullYear() + 1, 0, 1));
    }
  };

  const handlePrevious = () => {
    if (currentView === 'month') {
      setCurrentDate(current => subMonths(current, 1));
    } else if (currentView === 'week') {
      setCurrentDate(current => subWeeks(current, 1));
    } else {
      setCurrentDate(current => new Date(current.getFullYear() - 1, 0, 1));
    }
  };

  const handleCarouselDrag = (event: any) => {
    if (event.direction > 0) handleNext();
    if (event.direction < 0) handlePrevious();
  };

  if (isLoading) {
    return (
      <Layout>
        <div className="flex items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-primary"></div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <HeaderControls 
        currentView={currentView}
        onViewChange={(value) => setCurrentView(value as ViewType)}
        isLocked={isLocked}
        onToggleLock={handleToggleLock}
        onReset={handleResetActivities}
      />

      <DateNavigation 
        currentDate={currentDate}
        currentView={currentView}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
      
      <MainContent
        currentView={currentView}
        currentDate={currentDate}
        activities={activities}
        selectedColor={selectedColor}
        onToggleActivity={handleToggleActivity}
        onCarouselDrag={handleCarouselDrag}
        isLocked={isLocked}
      />

      <div className="flex flex-col items-center gap-4">
        <ShiftPicker 
          selectedColor={selectedColor} 
          selectedShiftType={selectedShiftType}
          selectedHours={selectedHours}
          onColorSelect={setSelectedColor}
          onShiftTypeSelect={setSelectedShiftType}
          onHoursSelect={setSelectedHours}
          isLocked={isLocked}
          onToggleLock={handleToggleLock}
          onReset={handleResetActivities}
        />
      </div>
    </Layout>
  );
};

export default Index;